//changed

import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/Seo";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HeaderUnderlined from "../components/HeaderUnderlined";
import PostPreview from "../components/ListPosts";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const WebPortfolio = ({ data }) => {
  const { title, body, relationships, field_image } = data.allNodePage.nodes[0];
  const websites = data.allNodePortfolio.nodes;
  const thumbnail = getImage(
    relationships.field_image.localFile.childImageSharp
  );
  return (
    <Layout>
      <Seo title={title} />
      <Container fluid="lg">
        <Row>
          <Col
            xl={3}
            lg={3}
            md={4}
            sm={6}
            xs={12}
            style={{ backgroundColor: "#214e0d59" }}
          >
            <div className="shadow-sm bg-secondary">
              <GatsbyImage image={thumbnail} alt={field_image.alt} />
            </div>
          </Col>
          <Col className="p-5 bg-white">
            <HeaderUnderlined titlesrc={title} className="fadein" />
            <div
              dangerouslySetInnerHTML={{
                __html: body.processed,
              }}
            />
          </Col>
        </Row>
      </Container>
      <div
        className="bg-primary-opas"
        style={{ backgroundBlendMode: "darken" }}
      >
        <Container fluid="lg" className="wavebackground">
          <Container fluid className="pt-5">
            {websites.map((website) => {
              return <PostPreview key={website.id} post={website} />;
            })}
          </Container>
        </Container>
      </div>
    </Layout>
  );
};
export const query = graphql`
  {
    allNodePage(filter: { path: { alias: { eq: "/web-portfolio" } } }) {
      nodes {
        id
        title
        body {
          processed
        }
        relationships {
          field_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 70
                  width: 500
                  height: 500
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        field_image {
          alt
        }
      }
    }
    allNodePortfolio(sort: { order: DESC, fields: created }) {
      nodes {
        id
        title
        body {
          summary
        }
        path {
          alias
        }
        relationships {
          field_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 70
                  width: 500
                  height: 500
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        field_new
        field_image {
          alt
        }
      }
    }
  }
`;

export default WebPortfolio;
