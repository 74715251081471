import React from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { FaBookmark } from "react-icons/fa";
import coloredDots from "../images/fourdots.svg";

const ListPosts = ({ post }) => {
  return (
    <>
      <Row className="pb-5">
        <Col xl={3} lg={3} md={4} sm={6} xs={12} className="bg-dark">
          <div>
            <Link to={post.path.alias}>
              <GatsbyImage
                image={
                  post.relationships.field_image.localFile.childImageSharp
                    .gatsbyImageData
                }
                alt={post.field_image.alt}
              />
            </Link>
          </div>
        </Col>
        <Col>
          {post.field_new && <FaBookmarkStyled />}
          <CardBlock>
            <CardTitle style={{ position: "relative" }}>
              <h5>{post.title}</h5>
              <div className="line003"></div>
              <br />
            </CardTitle>
            <CardArticle>{post.body.summary}</CardArticle>
            <CardFooter>
              <Dots src={coloredDots} alt="colored dots" />
              <Link to={post.path.alias} className="text-decoration-none">
                <h6 className="text-white">Читать дальше</h6>
              </Link>
            </CardFooter>
          </CardBlock>
        </Col>
      </Row>
    </>
  );
};
ListPosts.propTypes = {
  post: PropTypes.object.isRequired,
};

export default ListPosts;

const CardBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: white;
`;

const CardTitle = styled.div`
  padding: 1rem;
  font-size: 1.25rem;
  font-family: Oswald;
  font-weight: 500;
  background-color: white;
  padding-left: 50px;
  padding-right: 50px;
  height: 10px;
`;
const CardArticle = styled.article`
  padding: 1rem;
  background-color: white;
  padding-top: 3.5rem;
  line-height: 1.5rem;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  padding-left: 50px;
  padding-right: 50px;
`;
const CardFooter = styled.div`
  position: relative;
  padding: 1rem;
  -webkit-transition: background-color 500ms ease;
  -moz-transition: background-color 500ms ease;
  -o-transition: background-color 500ms ease;
  transition: background-color 500ms ease;
  transition: background-color 0.5s ease;
  background-color: #1e2b37;
  font-family: Oswald;
  font-weight: 500;
  padding-left: 50px;
  padding-right: 50px;
  &:hover {
    -webkit-transition: background-color 500ms ease;
    -moz-transition: background-color 500ms ease;
    -o-transition: background-color 500ms ease;
    transition: background-color 500ms ease;
    background-color: #344352;
  }
`;
const FaBookmarkStyled = styled(FaBookmark)`
  font-size: 3rem;
  position: absolute;
  right: 10px;
  top: -4px;
  z-index: 1;
  color: brown;
`;
const Dots = styled.img`
  position: absolute;
  right: 5px;
  top: -14px;
  width: 170px;
  height: 30px;
`;
